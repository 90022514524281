/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
        function search_mobile() {
          if ($('.search-init').length) {
            $('.search-init').on('click', function () {
              $('.form-search').css({
                'bottom': '-50px',
                'opacity': '1',
                'z-index': '9999'
              });
              $('.search-stop').css({
                'bottom': '-45px',
                'opacity': '1',
                'z-index': '9999'
              });
            });
            $('.search-stop').on('click', function () {
              $('.form-search').css({
                'bottom': '-25px',
                'opacity': '0',
                'z-index': '-1'
              });
              $('.search-stop').css({
                'bottom': '-25px',
                'opacity': '0',
                'z-index': '-1'
              });
            });
          }
        }
        search_mobile();
        $('.owl-carousel').owlCarousel({
          loop: true,
          margin: 10,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true
            },
            600: {
              items: 3,
              nav: false
            },
            1000: {
              items: 5,
              nav: true,
              loop: false
            }
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {

        $(document).ready(function () {
          $getTheACF = $('.getACF').val();
          $('.socicon-whatsapp').parent().attr('href', $getTheACF);

          $('a[title="Racing Online"]').attr('target', '_blank');
          if ('undefined' !== typeof LazyLoad) {
            var lazyLoad = new LazyLoad({
              elements_selector: "img",
            });
          }
        });
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      768: {
        items: 1,
        dots: true,
      }
    }
  });

  dotcount = 1;
  jQuery('.owl-dot').each(function () {
    jQuery(this).addClass('dotnumber' + dotcount);
    jQuery(this).attr('data-info', dotcount);
    dotcount = dotcount + 1;
  });

  slidecount = 1;
  jQuery('.owl-item').not('.cloned').each(function () {
    jQuery(this).addClass('slidenumber' + slidecount);
    slidecount = slidecount + 1;
  });

  jQuery('.owl-dot').each(function () {
    grab = jQuery(this).data('info');
    slidegrab = jQuery('.slidenumber' + grab + ' img').attr('src');
    console.log(slidegrab);
    jQuery(this).css("background-image", "url(" + slidegrab + ")");
  });

  amount = jQuery('.owl-dot').length;
  gotowidth = 100 / amount;

  jQuery('.owl-dot').css("width", gotowidth + "%");
  newwidth = jQuery('.owl-dot').width();
  jQuery('.owl-dot').css("height", newwidth + "px");

  $('.owl-dot').on('click', function () {
    owl.trigger('to.owl.carousel', [$(this).index(), 300]);
    $('.owl-dot').removeClass('active');
    $(this).addClass('active');
  });

  $('document').ready(function () {
    var $btn = $('.eventoClick');
    if (!$btn.length) {
      return false;
    }
    $btn.on('click', function (element) {
      gtag('event', 'Click', {
        'send_to': 'UA-100949303-1',
        'event_category': 'e-Content',
        'event_label': 'download',
        'value': $(element.currentTarget).data('title'),
      });
    });
  });

})(jQuery); // Fully reference jQuery after this point.
